import { graphql, useStaticQuery } from "gatsby"
import { FluidObject } from "gatsby-image"

interface DesktopFluidObject {
  desktop: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

export const useShowcaseImage = () => {
  const query = graphql`
    query {
      desktop: file(relativePath: { eq: "img/_MG_8534_sRGB.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `
  const data = useStaticQuery<DesktopFluidObject>(query)
  return data.desktop.childImageSharp.fluid
}
