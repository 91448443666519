import React, { FC } from "react"
import GatsbyImage, { FixedObject } from "gatsby-image"
import { Grid } from "@material-ui/core"

interface Props {
  logo: FixedObject
  alt: string
  title: string
}

const LogoGridItem: FC<Props> = ({ logo, alt, title }) => {
  return (
    <Grid item>
      <GatsbyImage fixed={logo} alt={alt} title={title} />
    </Grid>
  )
}

export default LogoGridItem
