import React, { FC } from "react"
import GatsbyImage from "gatsby-image"
import { Container } from "@material-ui/core"
import { useShowcaseImage } from "../hooks/images"

const Showcase: FC = () => {
  const imageData = useShowcaseImage()
  return (
    <Container maxWidth="md">
      <GatsbyImage Tag="section" fluid={imageData} />
    </Container>
  )
}

export default Showcase
