import React, { FC } from "react"
import { graphql, PageProps } from "gatsby"
import { FixedObject } from "gatsby-image"
import { Grid, Typography } from "@material-ui/core"
import Layout from "../components/layout"
import LogoGridItem from "../components/logoGridItem"
import Showcase from "../components/showcase"

export const query = graphql`
  query {
    allFile(filter: { relativePath: { glob: "img/*_logo.jpg" } }) {
      nodes {
        childImageSharp {
          fixed(width: 235, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

interface IndexQueryProps {
  allFile: {
    nodes: [
      {
        childImageSharp: {
          fixed: FixedObject
        }
      }
    ]
  }
}

const IndexPage: FC<PageProps<IndexQueryProps>> = ({ data }) => {
  const logos = data.allFile.nodes.map(node => node.childImageSharp.fixed)

  function findLogo(imagesSharpFixed: FixedObject[], filename: string) {
    return imagesSharpFixed.find(imageSharpFixed =>
      imageSharpFixed.src.includes(filename)
    )
  }

  const renderLogo = (logoFileName: string, title: string, alt: string) => {
    const logo = findLogo(logos, logoFileName)
    if (!logo) {
      return null
    }

    return <LogoGridItem logo={logo} title={title} alt={alt} />
  }

  return (
    <Layout>
      <Showcase />
      <Typography variant="h4">
        Willkommen bei der Orchestergemeinschaft München-Andechs e.V.
      </Typography>
      <Typography paragraph>
        Die Orchestergemeinschaft München-Andechs e.V. (OGeMA) ist ein
        gemeinnütziger Dachverband, der drei verschiedene Orchester vereinigt,
        ihre Konzerte strukturiert und organisiert.
      </Typography>
      <Grid container justify="space-around">
        {renderLogo(
          "camerata_logo.jpg",
          "Camerata Andechs",
          "Camerata Andechs Logo"
        )}
        {renderLogo("soma_logo.jpg", "SOMA", "SOMA Logo")}
        {renderLogo(
          "akademie_logo.jpg",
          "Münchner Herbstakademie",
          "Münchner Herbstakademie Logo"
        )}
      </Grid>
    </Layout>
  )
}

export default IndexPage
